import React, { MouseEvent, useCallback, useMemo } from 'react';
import { WheelG, WheelLayer } from './VehicleSchema.style';
import { TyreType } from './VehicleSchema.type';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { VehicleSchemaHoverAtom } from '../VehicleDetailsSchema/VehicleDetailsSchema.atom';
import { VehicleDetailsActionMenuAtom } from '../VehicleDetailsActionMenu/VehicleDetailsActionMenu.atom';
import { VehicleDetailsWidgetAtom } from '../VehicleDetailsWidget/VehicleDetailsWidget.atom';
import { BesAtom } from './BesAtom';
import { CustomerSettings } from 'states/global/CustomerSettings';
import { RANK1, RANK2, RANK3 } from 'components/Ui/colors';
import { BesLabelHoverAtom } from 'components/Mixs/BesLabelHover/BesLabelHover.atom';
import { Theme } from 'states/global/Theme';
import { TyreDetail } from 'models/Wheel.type';
import { AlertConfigurationPosition } from 'components/Alert/AlertsConfigurationFilterInput/atom';
import { getBesRank } from 'helpers';

const colors = {
    1: RANK1,
    2: RANK2,
    3: RANK3
};

export const Tyre: React.FC<TyreType> = ({
    viewBox,
    front,
    position,
    vehicleAtomId,
    allowDisplaySelectedTyre,
    allowSelectTyre,
    color
}): JSX.Element => {
    const [alertConfigurationPosition, setAlertConfigurationPosition] = useRecoilState(AlertConfigurationPosition);
    const [tyreHover, setTyreHover] = useRecoilState(
        VehicleSchemaHoverAtom(vehicleAtomId ? `${'VehicleSchemaHoverAtom-' + vehicleAtomId}` : undefined)
    );
    const setVehicleDetailsActionMenuAtom = useSetRecoilState(VehicleDetailsActionMenuAtom);
    const vehicleDetailsWidgetAtom = useRecoilValue(
        VehicleDetailsWidgetAtom(vehicleAtomId ? `${'VehicleSchemaAtom-' + vehicleAtomId}` : undefined)
    );
    const tyreDetail = useMemo(
        () => vehicleDetailsWidgetAtom.tyreDetails?.find((tyre) => tyre.wheel.position === position),
        [position, vehicleDetailsWidgetAtom.tyreDetails]
    );
    const besData = useRecoilValue(BesAtom);
    const tyreData = besData?.externalSystemMddBesTyreLogDetail.find(
        (tyreBesData) => tyreBesData.position === position
    );
    const customerSettings = useRecoilValue(CustomerSettings);
    const setBesLabelHoverAtom = useSetRecoilState(BesLabelHoverAtom);
    const theme = useRecoilValue(Theme);

    const handleClick = (event: MouseEvent<SVGGElement>): void => {
        setVehicleDetailsActionMenuAtom({
            isOpen: true,
            data: tyreDetail as TyreDetail,
            positionX: event.clientX,
            positionY: event.clientY
        });
    };

    const getAlert = useCallback(() => {
        let alertLevel = 0;
        if (tyreDetail && tyreDetail.attachedSensors?.length) {
            for (let i = 0; i < tyreDetail.attachedSensors.length; i++) {
                const sensorAlert = tyreDetail.attachedSensors[i];
                if (sensorAlert.isTyreLifespan) {
                    return sensorAlert.sensorAlert;
                }
                if (sensorAlert?.sensorAlerts?.length) {
                    for (let j = 0; j < sensorAlert.sensorAlerts.length; j++) {
                        if (sensorAlert.sensorAlerts[j]?.wheelPrediction?.notificationEventLevel) {
                            const wheelPredLevel =
                                (sensorAlert?.sensorAlerts[j]?.wheelPrediction?.notificationEventLevel || 0) > 1
                                    ? 3
                                    : 2;
                            if (alertLevel < wheelPredLevel) alertLevel = wheelPredLevel;
                        } else if (sensorAlert.sensorAlert && alertLevel < sensorAlert.sensorAlert) {
                            alertLevel = sensorAlert.sensorAlert;
                        }
                    }
                }
            }
        }
        return alertLevel;
    }, [tyreDetail]);

    const hasSensor = useCallback(() => {
        return !!tyreDetail?.attachedSensors?.length;
    }, [tyreDetail]);

    return (
        <svg viewBox={viewBox} id={`position_${position}`}>
            {tyreData && customerSettings.mdd_bes && tyreData.besSeverityRankTotal > 0 && (
                <g>
                    <rect
                        x={front ? '-10' : '0'}
                        y='62'
                        height='25'
                        width='27'
                        rx={3}
                        fill={
                            colors[
                                getBesRank(
                                    customerSettings.mdd_bes_display_name.toUpperCase(),
                                    tyreData.besSeverityRankTotal
                                )
                            ]
                        }
                        style={{ stroke: 'black', strokeWidth: 1, strokeOpacity: 0.5 }}
                    />
                    <text x={front ? '-5' : '5'} y={front ? '71' : '71'} fill='#fff' fontSize='8' fontWeight='700'>
                        {customerSettings.mdd_bes_display_name.toUpperCase()}
                    </text>
                    <text x={front ? '0' : '9.5'} y={front ? '83' : '83'} fill='#fff' fontSize='14' fontWeight='800'>
                        {tyreData.besSeverityRankTotal}
                    </text>
                    <rect
                        x={front ? '-10' : '0'}
                        y='62'
                        height='25'
                        width='27'
                        rx={3}
                        fillOpacity={0}
                        style={{ stroke: 'black', strokeWidth: 1, strokeOpacity: 0.5 }}
                        onMouseEnter={(event) => {
                            setBesLabelHoverAtom({
                                x: event.clientX,
                                y: event.clientY,
                                text: tyreData.lastJudgementDate,
                                display: true
                            });
                        }}
                        onMouseLeave={(event) => {
                            setBesLabelHoverAtom({
                                x: event.clientX,
                                y: event.clientY,
                                text: tyreData.lastJudgementDate,
                                display: false
                            });
                        }}
                    />
                </g>
            )}
            <WheelG
                transform={`rotate(${front ? 15 : 0})`}
                onClick={(e) => {
                    !vehicleAtomId && !allowDisplaySelectedTyre && handleClick(e);
                    if (allowSelectTyre) {
                        setAlertConfigurationPosition((current) => {
                            if (current.position.includes(position)) {
                                return {
                                    ...current,
                                    position: current.position.filter((currentPosition) => {
                                        return currentPosition !== position;
                                    })
                                };
                            }
                            return { ...current, position: [...current.position, position] };
                        });
                    }
                }}
                alertLevel={tyreHover.hasHover ? getAlert() : 0}
                $sensor={tyreHover.hasHover ? hasSensor() : false}
                $theme={theme?.mode}
                customColor={
                    color
                        ? color
                        : allowDisplaySelectedTyre &&
                          ((alertConfigurationPosition.position &&
                              alertConfigurationPosition.position.includes(position)) ||
                              alertConfigurationPosition.position.length === 0)
                        ? alertConfigurationPosition.color
                        : undefined
                }
                data-testid={`Wheel-OpenActionList-${tyreDetail?.wheel.position}-Button`}
                allowClick={allowSelectTyre}
            >
                <path
                    d='M 24.89 7.075 L 21.79 7.075 L 19.79 9.075 C 19.69 9.175
                    19.59 9.175 19.39 9.175 L 16.39 9.175 C 16.09 9.175 15.89
                    8.975 15.89 8.675 C 15.89 8.375 16.09 8.175 16.39 8.175 L
                    19.19 8.175 L 21.19 6.175 C 21.29 6.075 21.39 6.075 21.59
                    6.075 L 24.89 6.075 L 24.89 5.275 C 24.89 3.775 24.19 2.475
                    23.19 1.575 L 21.79 1.575 L 19.79 3.575 C 19.69 3.675 19.59
                    3.675 19.39 3.675 L 16.39 3.675 C 16.09 3.675 15.89 3.475
                    15.89 3.175 C 15.89 2.875 16.09 2.675 16.39 2.675 L 19.19
                    2.675 L 21.19 0.675 C 21.29 0.575 21.39 0.575 21.49 0.575 C
                    20.99 0.375 20.49 0.275 19.89 0.275 L 5.29 0.275 C 2.69 0.275
                    0.59 2.275 0.39 4.775 L 3.19 4.775 L 5.19 2.775 C 5.29 2.675
                    5.39 2.675 5.59 2.675 L 8.89 2.675 C 9.19 2.675 9.39 2.875 9.39
                    3.175 C 9.39 3.475 9.19 3.675 8.89 3.675 L 5.79 3.675 L 3.79 5.675 C
                    3.69 5.775 3.59 5.775 3.39 5.775 L 0.39 5.775 L 0.39 10.375 L 3.19
                    10.375 L 5.19 8.375 C 5.29 8.275 5.39 8.275 5.59 8.275 L 8.89
                    8.275 C 9.19 8.275 9.39 8.475 9.39 8.775 C 9.39 9.075 9.19 9.275
                    8.89 9.275 L 5.79 9.275 L 3.79 11.275 C 3.69 11.375 3.59 11.375
                    3.39 11.375 L 0.39 11.375 L 0.39 15.975 L 3.19 15.975 L 5.19 13.975 C
                    5.29 13.875 5.39 13.875 5.59 13.875 L 8.89 13.875 C 9.19 13.875 9.39
                    14.075 9.39 14.375 C 9.39 14.675 9.19 14.875 8.89 14.875 L 5.79 14.875 L
                    3.79 16.875 C 3.69 16.975 3.59 16.975 3.39 16.975 L 0.39 16.975 L 0.39
                    21.575 L 3.19 21.575 L 5.19 19.575 C 5.29 19.475 5.39 19.475 5.59 19.475 L
                    8.89 19.475 C 9.19 19.475 9.39 19.675 9.39 19.975 C 9.39 20.275 9.19 20.475
                    8.89 20.475 L 5.79 20.475 L 3.79 22.475 C 3.69 22.575 3.59 22.575 3.39 22.575 L
                    0.39 22.575 L 0.39 27.175 L 3.19 27.175 L 5.19 25.175 C 5.29 25.075 5.39
                    25.075 5.59 25.075 L 8.89 25.075 C 9.19 25.075 9.39 25.275 9.39 25.575 C
                    9.39 25.875 9.19 26.075 8.89 26.075 L 5.79 26.075 L 3.79 28.075 C 3.69
                    28.175 3.59 28.175 3.39 28.175 L 0.39 28.175 L 0.39 32.775 L 3.19 32.775 L
                    5.19 30.775 C 5.29 30.675 5.39 30.675 5.59 30.675 L 8.89 30.675 C 9.19
                    30.675 9.39 30.875 9.39 31.175 C 9.39 31.475 9.19 31.675 8.89 31.675 L 5.79
                    31.675 L 3.79 33.675 C 3.69 33.775 3.59 33.775 3.39 33.775 L 0.39 33.775 L
                    0.39 38.375 L 3.19 38.375 L 5.19 36.375 C 5.29 36.275 5.39 36.275 5.59 36.275 L
                    8.89 36.275 C 9.19 36.275 9.39 36.475 9.39 36.775 C 9.39 37.075 9.19 37.275 8.89
                    37.275 L 5.79 37.275 L 3.79 39.275 C 3.69 39.375 3.59 39.375 3.39 39.375 L 0.39
                    39.375 L 0.39 43.975 L 3.19 43.975 L 5.19 41.975 C 5.29 41.875 5.39 41.875 5.59
                    41.875 L 8.89 41.875 C 9.19 41.875 9.39 42.075 9.39 42.375 C 9.39 42.675 9.19
                    42.875 8.89 42.875 L 5.79 42.875 L 3.79 44.875 C 3.69 44.975 3.59 44.975 3.39
                    44.975 L 0.39 44.975 L 0.39 49.575 L 3.19 49.575 L 5.19 47.575 C 5.29 47.475
                    5.39 47.475 5.59 47.475 L 8.89 47.475 C 9.19 47.475 9.39 47.675 9.39 47.975 C
                    9.39 48.275 9.19 48.475 8.89 48.475 L 5.79 48.475 L 3.79 50.475 C 3.69 50.575
                    3.59 50.575 3.39 50.575 L 0.39 50.575 L 0.39 51.075 C 0.39 52.775 1.19 54.275
                    2.49 55.175 L 3.19 55.175 L 5.19 53.175 C 5.29 53.075 5.39 53.075 5.59 53.075 L
                    8.89 53.075 C 9.19 53.075 9.39 53.275 9.39 53.575 C 9.39 53.875 9.19 54.075 8.89
                    54.075 L 5.79 54.075 L 3.89 55.975 C 4.39 56.075 4.79 56.175 5.29 56.175 L 19.89
                    56.175 C 22.39 56.175 24.39 54.375 24.79 52.075 L 21.79 52.075 L 19.79 54.075 C
                    19.69 54.175 19.59 54.175 19.39 54.175 L 16.39 54.175 C 16.09 54.175 15.89 53.975
                    15.89 53.675 C 15.89 53.375 16.09 53.175 16.39 53.175 L 19.19 53.175 L 21.19 51.175 C
                    21.29 51.075 21.39 51.075 21.59 51.075 L 24.89 51.075 L 24.89 45.975 L 21.79 45.975 L
                    19.79 47.975 C 19.69 48.075 19.59 48.075 19.39 48.075 L 16.39 48.075 C 16.09 48.075
                    15.89 47.875 15.89 47.575 C 15.89 47.275 16.09 47.075 16.39 47.075 L 19.19 47.075 L
                    21.19 45.075 C 21.29 44.975 21.39 44.975 21.59 44.975 L 24.89 44.975 L 24.89 40.375 L
                    21.79 40.375 L 19.79 42.375 C 19.69 42.475 19.59 42.475 19.39 42.475 L 16.39 42.475 C
                    16.09 42.475 15.89 42.275 15.89 41.975 C 15.89 41.675 16.09 41.475 16.39 41.475 L 19.19
                    41.475 L 21.19 39.475 C 21.29 39.375 21.39 39.375 21.59 39.375 L 24.89 39.375 L 24.89
                    34.775 L 21.79 34.775 L 19.79 36.775 C 19.69 36.875 19.59 36.875 19.39 36.875 L 16.39
                    36.875 C 16.09 36.875 15.89 36.675 15.89 36.375 C 15.89 36.075 16.09 35.875 16.39
                    35.875 L 19.19 35.875 L 21.19 33.875 C 21.29 33.775 21.39 33.775 21.59 33.775 L
                    24.89 33.775 L 24.89 29.175 L 21.79 29.175 L 19.79 31.175 C 19.69 31.275 19.59 31.275
                    19.39 31.275 L 16.39 31.275 C 16.09 31.275 15.89 31.075 15.89 30.775 C 15.89 30.475
                    16.09 30.275 16.39 30.275 L 19.19 30.275 L 21.19 28.275 C 21.29 28.175 21.39 28.175
                    21.59 28.175 L 24.89 28.175 L 24.89 23.575 L 21.79 23.575 L 19.79 25.575 C 19.69
                    25.675 19.59 25.675 19.39 25.675 L 16.39 25.675 C 16.09 25.675 15.89 25.475 15.89
                    25.175 C 15.89 24.875 16.09 24.675 16.39 24.675 L 19.19 24.675 L 21.19 22.675 C 21.29
                    22.575 21.39 22.575 21.59 22.575 L 24.89 22.575 L 24.89 17.975 L 21.79 17.975 L 19.79
                    19.975 C 19.69 20.075 19.59 20.075 19.39 20.075 L 16.39 20.075 C 16.09 20.075 15.89
                    19.875 15.89 19.575 C 15.89 19.275 16.09 19.075 16.39 19.075 L 19.19 19.075 L 21.19
                    17.075 C 21.29 16.975 21.39 16.975 21.59 16.975 L 24.89 16.975 L 24.89 12.375 L 21.79
                    12.375 L 19.79 14.375 C 19.69 14.475 19.59 14.475 19.39 14.475 L 16.39 14.475 C 16.09
                    14.475 15.89 14.275 15.89 13.975 C 15.89 13.675 16.09 13.475 16.39 13.475 L 19.19
                    13.475 L 21.19 11.475 C 21.29 11.375 21.39 11.375 21.59 11.375 L 24.89 11.375 L 24.89 7.075 Z'
                />
                <WheelLayer
                    $hover={tyreHover.hasHover && tyreHover.hoveredPosition === tyreDetail?.wheel.position}
                    className='wheel-cover'
                    onMouseEnter={() => {
                        if (tyreHover.hasHover) {
                            setTyreHover({
                                ...tyreHover,
                                hoveredPosition: position || 0
                            });
                        }
                    }}
                    onMouseLeave={() => {
                        if (tyreHover.hasHover) {
                            setTyreHover({
                                ...tyreHover,
                                hoveredPosition: 0
                            });
                        }
                    }}
                    d='M 19.831 56.335 L 5.331 56.335 C 2.531 56.335 0.331
                    54.103 0.331 51.265 L 0.331 5.23 C 0.331 2.39 2.531 0.16
                    5.331 0.16 L 19.831 0.16 C 22.631 0.16 24.831 2.39 24.831
                    5.23 L 24.831 51.265 C 24.831 54.103 22.531 56.335 19.831
                    56.335'
                />
            </WheelG>
        </svg>
    );
};
