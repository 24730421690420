import React, { useCallback, useEffect, useReducer } from 'react';
import { TemperaturePressureListContent } from './TemperaturePressureList.view';
import { TemperaturePressureActionsEnum, TemperaturePressureListProps } from './TemperaturePressureList.type';
import { InicialStateTemperaturePressure, TemperaturePressureReducer } from './TemperaturePressureList.reducer';
import Tyre from 'api/Tyre';
import { useQuery } from '@tanstack/react-query';
import { TyreTempPressReport, TyreTempPressReportData, Wheels } from 'models/Tyre.type';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { ContainerNoData } from './TemperaturePressureList.style';
import { AlertLevelEnum } from '../TemperaturePressureTable/TemperaturePressureTable.type';
import { UserInfo } from 'states/global/User';
import { TempPressFilters } from '../TemperaturePressureTable/TempPressFilters.atom';
import UiLoadingPage from 'components/Ui/Components/UiLoadingPage/UiLoadingPage';
import { CustomerSettings } from 'states/global/CustomerSettings';
import { Wrapper } from 'helpers/wrapper';
import { DateTime } from 'luxon';

const TemperaturePressureList: React.FC<TemperaturePressureListProps> = ({
    tableDisplay,
    valueInputSearch,
    refresh,
    filterByAlert,
    showScroll,
    filterVehicleGroup,
    handleRefresh,
    setFilterByAlert,
    setTempPredProblem
}): JSX.Element => {
    const [tempAndPressState, dispachTempAndPressState] = useReducer(
        TemperaturePressureReducer,
        InicialStateTemperaturePressure
    );
    const [tempPressFiltersAtom, setTempPressFiltersAtom] = useRecoilState(TempPressFilters);
    const apiTyre = new Tyre();
    const { t: translate } = useTranslation();
    const userInfo = useRecoilValue(UserInfo);
    const customerSettings = useRecoilValue(CustomerSettings);

    const removeOutOfService = (data: TyreTempPressReport[]): TyreTempPressReport[] => {
        return userInfo.user?.userSetting.showOutOfService
            ? data
            : data.filter((vehicle) => vehicle.outOfService === 0);
    };

    useEffect(() => {
        dispachTempAndPressState({
            type: TemperaturePressureActionsEnum.SET_WET_TYRES,
            payload: customerSettings.wet_tyres
        });
    }, [customerSettings.wet_tyres]);

    const {
        data: tempAndPressData,
        refetch: tempAndPressRefetch,
        isFetched: isLoading
    } = useQuery<TyreTempPressReport[], Error, TyreTempPressReportData>(
        ['tempAndPressData'],
        () => apiTyre.getTempPressReport(),
        {
            refetchOnWindowFocus: false,
            refetchInterval: valueInputSearch.length ? false : 30000,
            retry: 1,
            retryDelay: 1000,
            onSuccess: (response) => {
                let orderedDataTempAndPress: TyreTempPressReport[] =
                    response && parseData(removeOutOfService(response.tyreTempPressReport));

                if (response?.tyreTempPressReport) {
                    if (tempAndPressState.orderDirection) {
                        if (
                            tempAndPressState.orderDirection.column === 'severity_of_alert' ||
                            tempAndPressState.orderDirection.column === 'severity+name'
                        ) {
                            orderedDataTempAndPress = sortTemPressListCustom(
                                orderedDataTempAndPress,
                                tempAndPressState.orderDirection.order,
                                false,
                                tempAndPressState.orderDirection.column === 'severity+name'
                            );
                        } else {
                            sortTemPressList(
                                orderedDataTempAndPress,
                                tempAndPressState.orderDirection.order,
                                tempAndPressState.orderDirection.column,
                                tempAndPressState.orderDirection.column === 'lastTransmission'
                            );
                        }
                    }

                    if (filterByAlert) {
                        orderedDataTempAndPress = handleFilterByAlert(orderedDataTempAndPress, filterByAlert);
                    }

                    if (filterVehicleGroup.value) {
                        orderedDataTempAndPress = handleFilterByVehicleGroup(orderedDataTempAndPress);
                    }

                    dispachTempAndPressState({
                        type: TemperaturePressureActionsEnum.SET_VEHICLES_LIST,
                        payload: orderedDataTempAndPress
                    });
                    !valueInputSearch.length &&
                        dispachTempAndPressState({ type: TemperaturePressureActionsEnum.SET_LOADING, payload: false });

                    if (customerSettings.temperature_prediction) {
                        let isWrong = false;
                        for (let i = 0; i < response.tyreTempPressReport.length; i++) {
                            const vehicle = response.tyreTempPressReport[i];
                            for (let j = 0; j < vehicle.wheels.length; j++) {
                                const wheel = vehicle.wheels[j];
                                if (wheel.lastTemperaturePredictedAt) {
                                    const tempPredTime = DateTime.fromISO(wheel.lastTemperaturePredictedAt, {
                                        zone: 'UTC'
                                    });
                                    const currentTime = DateTime.now().setZone('UTC');
                                    const diff = currentTime.diff(tempPredTime, 'minutes').minutes;

                                    if (diff <= 6) {
                                        return;
                                    }
                                    isWrong = true;
                                }
                            }
                        }
                        setTempPredProblem(isWrong);
                    }
                } else {
                    dispachTempAndPressState({
                        type: TemperaturePressureActionsEnum.SET_VEHICLES_LIST,
                        payload: []
                    });
                }
            }
        }
    );

    const sortTemPressList = (vehicles, orderBy, column, date = false): TyreTempPressReport[] => {
        switch (orderBy) {
            case 'asc':
                return vehicles.sort((vehicle, newVehicle) => {
                    if (date) {
                        return new Date(vehicle[column]) > new Date(newVehicle[column])
                            ? 1
                            : new Date(newVehicle[column]) > new Date(vehicle[column])
                            ? -1
                            : 0;
                    }
                    return vehicle[column] > newVehicle[column] ? 1 : newVehicle[column] > vehicle[column] ? -1 : 0;
                });
            case 'desc':
                return vehicles.sort((vehicle, newVehicle) => {
                    if (date) {
                        return new Date(vehicle[column]) < new Date(newVehicle[column])
                            ? 1
                            : new Date(newVehicle[column]) < new Date(vehicle[column])
                            ? -1
                            : 0;
                    }
                    return vehicle[column] < newVehicle[column] ? 1 : newVehicle[column] < vehicle[column] ? -1 : 0;
                });
            default:
                return vehicles.sort((vehicle, newVehicle) =>
                    vehicle[column] > newVehicle[column] ? 1 : newVehicle[column] > vehicle[column] ? -1 : 0
                );
        }
    };

    const sortTemPressListCustom = (
        vehicles,
        orderBy,
        resetAlertLevelFilter = false,
        severityPlusName = false
    ): TyreTempPressReport[] => {
        vehicles = vehicles.map((listWheel) => {
            let highestAlertLevel: number = orderBy === 'asc' ? 8 : 0;
            let countAlertLevelLow = 0;
            let countAlertLevel = 0;
            resetAlertLevelFilter && setFilterByAlert(AlertLevelEnum.ALERT_LEVEL_0);

            listWheel.wheels.map((wheel) => {
                if (wheel.pressureLevel && orderBy !== 'asc') {
                    if (wheel.pressureLevel > highestAlertLevel) highestAlertLevel = wheel.pressureLevel;
                    wheel.pressureLevel == 3 && countAlertLevel++;
                }
                if (wheel.pressureHotLevel && orderBy !== 'asc') {
                    if (wheel.pressureHotLevel > highestAlertLevel) highestAlertLevel = wheel.pressureHotLevel;
                    wheel.pressureHotLevel == 3 && countAlertLevel++;
                }
                if (wheel.temperatureLevel && orderBy !== 'asc') {
                    if (wheel.temperatureLevel > highestAlertLevel) highestAlertLevel = wheel.temperatureLevel;
                    wheel.temperatureLevel == 3 && countAlertLevel++;
                }
                if (wheel.pressureLevel && wheel.pressureLevel < highestAlertLevel && orderBy === 'asc') {
                    highestAlertLevel = wheel.pressureLevel;
                    countAlertLevelLow++;
                }
                if (wheel.pressureHotLevel && wheel.pressureHotLevel < highestAlertLevel && orderBy === 'asc') {
                    highestAlertLevel = wheel.pressureHotLevel;
                    countAlertLevelLow++;
                }
                if (wheel.temperatureLevel && wheel.temperatureLevel < highestAlertLevel && orderBy === 'asc') {
                    highestAlertLevel = wheel.temperatureLevel;
                    countAlertLevelLow++;
                }
                return wheel;
            });

            listWheel.wheelFilterByAlert = highestAlertLevel;
            listWheel.countAlertLevel = countAlertLevel;
            listWheel.countAlertLevelLow = countAlertLevelLow;
            return listWheel;
        });

        if (orderBy === 'asc') {
            vehicles = vehicles.sort((vehicle, newVehicle) =>
                vehicle.countAlertLevelLow > newVehicle.countAlertLevelLow
                    ? 1
                    : newVehicle.countAlertLevelLow > vehicle.countAlertLevelLow
                    ? -1
                    : 0
            );
            vehicles = vehicles.sort((vehicle, newVehicle) =>
                vehicle.wheelFilterByAlert > newVehicle.wheelFilterByAlert
                    ? 1
                    : newVehicle.wheelFilterByAlert > vehicle.wheelFilterByAlert
                    ? -1
                    : 0
            );
        } else {
            vehicles = vehicles.sort((vehicle, newVehicle) =>
                vehicle.countAlertLevel < newVehicle.countAlertLevel
                    ? 1
                    : newVehicle.countAlertLevel < vehicle.countAlertLevel
                    ? -1
                    : 0
            );
            vehicles = vehicles.sort((vehicle, newVehicle) =>
                vehicle.wheelFilterByAlert < newVehicle.wheelFilterByAlert
                    ? 1
                    : newVehicle.wheelFilterByAlert < vehicle.wheelFilterByAlert
                    ? -1
                    : 0
            );

            if (severityPlusName) {
                vehicles.sort(function (vehicle, newVehicle) {
                    if (vehicle.wheelFilterByAlert < newVehicle.wheelFilterByAlert) {
                        return 1;
                    } else if (vehicle.wheelFilterByAlert > newVehicle.wheelFilterByAlert) {
                        return -1;
                    } else if (newVehicle.name.localeCompare(vehicle.name)) {
                        return vehicle.name.localeCompare(newVehicle.name);
                    } else {
                        return 0;
                    }
                });
            }
        }
        return vehicles;
    };

    const containsRecomValues = (): boolean => {
        return ['hot_opt', 'cold_opt'].includes(tableDisplay);
    };

    const handleSortRequest = (
        column: string,
        order: 'desc' | 'asc' | null,
        date = false,
        custom = false,
        severityPlusName = false,
        sort = false
    ): void => {
        dispachTempAndPressState({
            type: TemperaturePressureActionsEnum.SET_ORDER_DIRECTION,
            payload: { column, order }
        });
        if (order) {
            dispachTempAndPressState({
                type: TemperaturePressureActionsEnum.SET_VEHICLES_LIST,
                payload: custom
                    ? sortTemPressListCustom(tempAndPressState.vehiclesList, order, true, severityPlusName)
                    : sortTemPressList(tempAndPressState.vehiclesList, order, column, date)
            });
        }
        !sort &&
            setTempPressFiltersAtom({
                ...tempPressFiltersAtom,
                sortBy: null
            });
    };

    const parseData = useCallback(
        (data: TyreTempPressReport[]): TyreTempPressReport[] => {
            if (data.length) {
                let maxPosition: number | string = 0;
                let newData: TyreTempPressReport[] = data.map((d): TyreTempPressReport => {
                    let prepareData: TyreTempPressReport = d;
                    prepareData.wheels.map((wheel) => {
                        if (wheel.customPosition > +maxPosition && wheel.status) {
                            maxPosition = wheel.customPosition;
                        }
                        wheel.hotPressureOptimal = customerSettings.wet_tyres
                            ? wheel.hotPressureOptimalWet
                            : wheel.hotPressureOptimal;
                        wheel.pressureLevel = wheel.coldPressure && wheel.hotPressure ? wheel.pressureLevel : null;
                        wheel.pressureHotLevel =
                            wheel.coldPressure && wheel.hotPressure ? wheel.pressureHotLevel : null;
                        wheel.temperatureLevel = wheel.temperature ? wheel.temperatureLevel : null;
                        return wheel;
                    });

                    return prepareData;
                });
                if (maxPosition > tempAndPressState.maxPosition) {
                    dispachTempAndPressState({
                        type: TemperaturePressureActionsEnum.SET_MAX_POSITION,
                        payload: maxPosition
                    });
                }
                return newData;
            }
            return data;
        },
        [tempAndPressState.maxPosition]
    );

    const handleSearchVehicle = useCallback(
        (data: TyreTempPressReport[]): TyreTempPressReport[] | [] => {
            if (valueInputSearch.length >= 1 && data && data) {
                if (data) {
                    let newList: TyreTempPressReport[] = [];
                    newList = data.filter(
                        (row) =>
                            row.name.toLowerCase().includes(valueInputSearch.toLowerCase()) ||
                            (row?.deviceId && row?.deviceId.toString().includes(valueInputSearch.toLowerCase()))
                    );
                    if (
                        tempAndPressState.orderDirection &&
                        tempAndPressState.orderDirection.column === 'severity_of_alert'
                    ) {
                        newList = handleFilterByAlert(parseData(newList), filterByAlert);
                        return sortTemPressListCustom(newList, tempAndPressState.orderDirection.column);
                    }
                    return handleFilterByAlert(parseData(newList), filterByAlert);
                }
            }
            if (data && !!data.length) {
                if (
                    tempAndPressState.orderDirection &&
                    tempAndPressState.orderDirection.column === 'severity_of_alert'
                ) {
                    data = handleFilterByAlert(parseData(data), filterByAlert);
                    return sortTemPressListCustom(data, tempAndPressState.orderDirection.column);
                }
                return handleFilterByAlert(parseData(data), filterByAlert);
            }

            return [];
        },
        [valueInputSearch, parseData]
    );

    const handleFilterByAlert = (data: TyreTempPressReport[], filterByAlert: number): TyreTempPressReport[] => {
        if (filterByAlert) {
            data = data.map((listWheel) => {
                const existAlert: Wheels[] = listWheel.wheels.filter(
                    (wheel) =>
                        wheel.pressureLevel === filterByAlert ||
                        wheel.pressureHotLevel === filterByAlert ||
                        wheel.temperatureLevel === filterByAlert
                );
                if (existAlert && existAlert.length) {
                    listWheel.wheelFilterByAlert = filterByAlert;
                }
                return listWheel;
            });
        } else {
            if (tempAndPressState.orderDirection && tempAndPressState.orderDirection.column === 'severity_of_alert') {
                return sortTemPressListCustom(data, tempAndPressState.orderDirection.column);
            }
            return data;
        }

        data = data.filter(
            (listWheel) => listWheel?.wheelFilterByAlert && listWheel.wheelFilterByAlert === filterByAlert
        );
        if (tempAndPressState.orderDirection && tempAndPressState.orderDirection.column === 'severity_of_alert') {
            return sortTemPressListCustom(data, tempAndPressState.orderDirection.column);
        }
        return data;
    };

    const handleFilterByVehicleGroup = (data: TyreTempPressReport[]): TyreTempPressReport[] => {
        return data.filter((vehicle) => vehicle.vehicleTypeId === filterVehicleGroup.value);
    };

    useEffect(() => {
        if (tempAndPressData && !!tempAndPressData.tyreTempPressReport.length) {
            dispachTempAndPressState({
                type: TemperaturePressureActionsEnum.SET_VEHICLES_LIST,
                payload: handleSearchVehicle(removeOutOfService(tempAndPressData.tyreTempPressReport))
            });
        }
    }, [handleSearchVehicle]);

    useEffect(() => {
        if (tempAndPressData && !!tempAndPressData.tyreTempPressReport.length) {
            tempAndPressRefetch();
            if (isLoading) {
                handleRefresh(true);
            }
        }
    }, [filterByAlert, filterVehicleGroup]);

    useEffect(() => {
        if (refresh) {
            dispachTempAndPressState({ type: TemperaturePressureActionsEnum.SET_LOADING, payload: true });
            tempAndPressRefetch();
            if (isLoading) {
                handleRefresh(false);
                !!valueInputSearch.length &&
                    dispachTempAndPressState({ type: TemperaturePressureActionsEnum.SET_LOADING, payload: false });
            }
        }
    }, [refresh, isLoading, handleRefresh, tempAndPressRefetch, valueInputSearch]);

    useEffect(() => {
        if (tempPressFiltersAtom.sortBy) {
            if (tempPressFiltersAtom.sortBy.column === 'severity+name') {
                handleSortRequest('severity+name', 'desc', false, true, true, true);
            } else {
                handleSortRequest(
                    tempPressFiltersAtom.sortBy.column,
                    tempPressFiltersAtom.sortBy.order,
                    false,
                    tempPressFiltersAtom.sortBy.column === 'severity_of_alert',
                    false,
                    true
                );
            }
        }
    }, [tempPressFiltersAtom.sortBy]);

    return tempAndPressState.loandingContent ? (
        <UiLoadingPage size='30px' testid='TemperaturePressureList-loading' />
    ) : tempAndPressState.vehiclesList && tempAndPressState.vehiclesList.length ? (
        <TemperaturePressureListContent
            data-testid='TemperaturePressureList-testid'
            tableDisplay={tableDisplay}
            containsRecomValues={containsRecomValues}
            vehiclesList={tempAndPressState.vehiclesList}
            orderDirection={tempAndPressState.orderDirection}
            handleSortRequest={handleSortRequest}
            maxPosition={tempAndPressState.maxPosition}
            showScroll={showScroll}
        />
    ) : (
        <ContainerNoData>
            <h3>{translate('t.there_no_data')}</h3>
        </ContainerNoData>
    );
};

export default Wrapper(TemperaturePressureList);
