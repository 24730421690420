import React, { useMemo } from 'react';
import UiIcon from 'components/Ui/Components/UiIcon';
import { applyStyleByMode } from 'helpers';
import { WHITE, SECONDARY_DARK_MODE } from 'components/Ui/colors';
import { useRecoilValue } from 'recoil';
import { Theme } from 'states/global/Theme';
import { DateTime } from 'luxon';

const TemperaturePredictionPeriodIcon: React.FC<{ time: string | null; tempAndPressPage?: boolean }> = ({
    time,
    tempAndPressPage
}) => {
    const ThemeMode = useRecoilValue(Theme);

    const lastValidatedTempPredPeriod = useMemo(() => {
        if (time) {
            const tempPredTime = DateTime.fromISO(time, { zone: 'UTC' });
            const currentTime = DateTime.now().setZone('UTC');
            const diff = currentTime.diff(tempPredTime, 'minutes').minutes;
            return diff > 6;
        }
        return false;
    }, [time]);

    return lastValidatedTempPredPeriod ? (
        <span className='fa-layers fa-fw'>
            <UiIcon
                icon={['fas', 'slash']}
                size='sm'
                fixedWidth
                color={applyStyleByMode({
                    theme: ThemeMode?.mode,
                    dark: WHITE,
                    light: tempAndPressPage ? 'rgb(35, 35, 35)' : SECONDARY_DARK_MODE
                })}
            />
            <UiIcon
                icon={['fas', 'chart-mixed-up-circle-currency']}
                size='sm'
                fixedWidth
                color={applyStyleByMode({
                    theme: ThemeMode?.mode,
                    dark: WHITE,
                    light: tempAndPressPage ? 'rgb(35, 35, 35)' : SECONDARY_DARK_MODE
                })}
            />
        </span>
    ) : (
        <></>
    );
};

export default TemperaturePredictionPeriodIcon;
