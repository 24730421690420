import React, { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { LazyLoadingAlertsListProps } from './LazyLoadingAlertsList.type';
import { AlertListContent, List, LoadingContainer } from './LazyLoadingAlertsList.style';
import AlertsList from 'components/AlertNotification/AlertsList/AlertsList';
import { typeAlert } from 'components/AlertNotification/AlertsListTabManager/AlertsListTabManager.type';
import { ModelTpmsAlerts } from 'models/TpmsAlerts.type';
import { CircularProgress } from '@mui/material';
import SpeedEvent from 'api/SpeedEvent';
import { GpsAlert } from 'models/SpeedEvent.type';
import NotificationEvent from 'api/NotificationEvent';
import VehicleEventAPI from 'api/VehicleEvent';
import { VehicleEventModel } from 'models/VehicleEvent.type';

const SpeedEventApi = new SpeedEvent();
const notificationEventAPI = new NotificationEvent();
const vehicleEventAPI = new VehicleEventAPI();

export const LazyLoadingAlertsList: React.FC<LazyLoadingAlertsListProps> = ({
    vehicleId,
    changeNumberOfAlerts,
    queryId,
    limit,
    alertType,
    tabNumber,
    solved,
    displayAlertActions,
    showSolveButton,
    showValidateButton,
    alertFilters,
    dense,
    wheelId,
    tab
}): JSX.Element => {
    const [actualPage, setActualPage] = React.useState<number>(0);
    const [hasMore, setHasMore] = React.useState<boolean>(false);
    const [dataItems, setDataItems] = React.useState<ModelTpmsAlerts[] | GpsAlert[] | VehicleEventModel[]>([]);

    const {
        data: dataTpms,
        isLoading: isLoadingTpms,
        isFetching: isFetchingTpms,
        refetch: refetchTpms
    } = useQuery(
        [`GetTPMS-${queryId}-${vehicleId}${alertFilters && alertFilters.type ? alertFilters.type : ''}`],
        () =>
            notificationEventAPI.getNotification({
                vehicleId: vehicleId,
                solved: solved,
                sensorId: undefined,
                page: actualPage,
                limit: limit,
                alertFilters: alertFilters,
                wheelId: wheelId,
                tab: tab
            }),
        {
            enabled: false,
            onSuccess: (tpmsDataOnSuccess) => {
                const hasMoreData = tpmsDataOnSuccess?.paginator?.page < tpmsDataOnSuccess?.paginator?.pageCount;
                if (hasMoreData) {
                    setActualPage(actualPage + 1);
                }
                setHasMore(hasMoreData);

                if (actualPage === 0) {
                    setDataItems(tpmsDataOnSuccess.items);
                } else {
                    setDataItems((current): ModelTpmsAlerts[] => {
                        return (current as ModelTpmsAlerts[]).concat(tpmsDataOnSuccess.items as ModelTpmsAlerts[]);
                    });
                }
            }
        }
    );

    const {
        data: dataGPS,
        isLoading: isLoadingGps,
        isFetching: isFetchingGPS,
        refetch: refetchGps
    } = useQuery(
        [`GetGPS${queryId}-${vehicleId}`],
        () =>
            SpeedEventApi.getAlerts({
                limit: limit,
                page: actualPage + 1,
                vehicleId: vehicleId,
                measuredFrom: undefined,
                orderParams: [{ id: 'measuredAt', desc: true }]
            }),
        {
            enabled: false,
            onSuccess: (gpsDataOnSuccess) => {
                const hasMoreData = gpsDataOnSuccess?.paginator?.page < gpsDataOnSuccess?.paginator?.pageCount;
                if (hasMoreData) {
                    setActualPage(actualPage + 1);
                }
                setHasMore(hasMoreData);

                if (actualPage === 0) {
                    setDataItems(gpsDataOnSuccess.items);
                } else {
                    setDataItems((current): GpsAlert[] => {
                        return (current as GpsAlert[]).concat(gpsDataOnSuccess.items as GpsAlert[]);
                    });
                }
            }
        }
    );

    const {
        data: dataVehicle,
        isLoading: isLoadingVehicle,
        isFetching: isFetchingVehicle,
        refetch: refetchVehicle
    } = useQuery(
        [`GetVehicleAlerts${queryId}-${vehicleId}`],
        () =>
            vehicleEventAPI.getAlerts({
                limit: limit,
                page: actualPage + 1,
                vehicleId: vehicleId,
                measuredFrom: undefined,
                orderParams: []
            }),
        {
            enabled: false,
            onSuccess: (vehicleAlertsData) => {
                const hasMoreData = vehicleAlertsData?.paginator?.page < vehicleAlertsData?.paginator?.pageCount;

                if (hasMoreData) {
                    setActualPage(actualPage + 1);
                }
                setHasMore(hasMoreData);

                if (actualPage === 0) {
                    setDataItems(vehicleAlertsData.items);
                } else {
                    setDataItems((current): VehicleEventModel[] => {
                        return (current as VehicleEventModel[]).concat(vehicleAlertsData.items as VehicleEventModel[]);
                    });
                }
            }
        }
    );

    if (alertType == typeAlert.VehicleEvent && !isLoadingVehicle) {
        changeNumberOfAlerts(alertType, dataVehicle?.paginator.totalCount || 0);
    } else if (alertType == typeAlert.GPS && !isLoadingGps) {
        changeNumberOfAlerts(alertType, dataGPS?.paginator.totalCount || 0);
    } else if (!isLoadingTpms) {
        changeNumberOfAlerts(alertType, dataTpms?.paginator.totalCount || 0);
    }

    const isLoadingInital = (): boolean => {
        switch (alertType) {
            case typeAlert.TPMS:
                return isLoadingTpms;
            case typeAlert.GPS:
                return isLoadingGps;
            case typeAlert.VehicleEvent:
                return isLoadingVehicle;
            default:
                return false;
        }
    };

    const shouldShowLoading = (): boolean => {
        const isLoading =
            (alertType === typeAlert.TPMS && (isLoadingTpms || isFetchingTpms)) ||
            (alertType === typeAlert.GPS && (isLoadingGps || isFetchingGPS)) ||
            (alertType === typeAlert.VehicleEvent && (isLoadingVehicle || isFetchingVehicle));
        return isLoading && hasMore;
    };

    const onScroll = (e): void => {
        if (e.target.scrollTop >= e.target.scrollHeight - e.target.offsetHeight) {
            hasMore &&
                (alertType === typeAlert.VehicleEvent
                    ? refetchVehicle()
                    : alertType === typeAlert.GPS
                    ? refetchGps()
                    : refetchTpms());
        }
    };

    useEffect(() => {
        switch (alertType) {
            case typeAlert.VehicleEvent:
                refetchVehicle();
                break;
            case typeAlert.GPS:
                refetchGps();
                break;
            default:
                refetchTpms();
                break;
        }

        return () => {
            setHasMore(false);
            setActualPage(0);
            setDataItems([]);
        };
    }, []);

    return (
        <AlertListContent data-testid={'LazyLoadingAlertsList-testid'}>
            <List onScroll={onScroll} maxHeight={alertType == typeAlert.GPS ? 550 : undefined}>
                <AlertsList
                    data={dataItems}
                    alertKey={queryId}
                    displayAlertActions={displayAlertActions}
                    typeAlert={alertType}
                    isLoading={isLoadingInital()}
                    displayBody
                    tabNumber={tabNumber}
                    disableRedirect
                    excludeModal={true}
                    showSolveButton={showSolveButton}
                    showValidateButton={showValidateButton}
                    dense={dense}
                    testId={`LazyLoadingAlertsList-Card-Alert-${queryId}`}
                />
                <LoadingContainer>{shouldShowLoading() && <CircularProgress size='1em' />}</LoadingContainer>
            </List>
        </AlertListContent>
    );
};
