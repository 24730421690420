import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import {
    CollapseTable,
    CustomText,
    CustomerSelectorContainer,
    CustomerSelectorPopover,
    LoadingContent,
    LocationMenuItem,
    RegionMenuItem,
    SearchMenuItem,
    SearchTextField
} from './CustomerSelector.style';
import { AutoCompleteOption, CustomerSelectorProps } from './CustomerSelector.type';
import CustomerClient from '../../../api/Customer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';
import { PatchAccountMutation } from '../../Mutations/Account/Account';
import { CustomerSelectorAtom } from '../CustomerSelectorAtom';
import { UiFullScreenLoaderAtom } from '../../Ui/Components/UiFullScreenLoader/UiFullScreenLoader.atom';
import { CustomerCodebook } from '../../../models/Customer.type';
import { User, UserInfo } from '../../../states/global/User';
import UiScroll from '../../Ui/Components/UiScroll/UiScroll';
import { useLocation, useNavigate } from 'react-router-dom';
import { CustomersAtom } from 'states/global/Customers';
import { Wrapper } from 'helpers/wrapper';
import { CustomerSettings as CustomerSettingsAtom } from 'states/global/CustomerSettings';
import { SearchVehicle } from 'components/Vehicle/VehicleStats/VehicleStats.atom';
import { setNewCancelToken, source } from 'helpers/api/HttpClient';
import { DateTime, Settings } from 'luxon';
import { UserModel } from 'models/User.type';
import Account from 'api/Account';
import { SelectedVehicle } from 'components/Alert/AlertsFilterVehiclesBy/atom';

const ApiAccount = new Account();

const customerClient = new CustomerClient();
const CustomerSelector: React.FC<CustomerSelectorProps> = (): JSX.Element => {
    const { t: translate } = useTranslation();
    const [selectorState, setSelectorState] = useRecoilState(CustomerSelectorAtom);
    const [searchCustomer, setSearchCustomer] = useState<string | undefined>();
    const setFullScreenLoaderState = useSetRecoilState(UiFullScreenLoaderAtom);
    const userInfo = useRecoilValue(UserInfo);
    const [UserState, setUserState] = useRecoilState(User);
    const [regionsData, setRegionsData] = useState<AutoCompleteOption[] | []>([]);
    const navigate = useNavigate();
    const location = useLocation();
    const setCustomers = useSetRecoilState(CustomersAtom);
    const resetCustomerSettings = useResetRecoilState(CustomerSettingsAtom);
    const resetSearchVehicle = useResetRecoilState(SearchVehicle);
    const resetSelectedVehicle = useResetRecoilState(SelectedVehicle);

    const getUser = async () => {
        try {
            const dataU: UserModel = await ApiAccount.getParsed();

            if (Object.keys(dataU).length) {
                setUserState(dataU);
                DateTime.local().setZone(dataU.userSetting.timezone.timezoneName || 'Europe/Bratislava');
                Settings.defaultZone = dataU.userSetting.timezone.timezoneName || 'Europe/Bratislava';
            }
        } catch (error) {
            // eslint-disable-next-line no-console
            console.warn('Get user ', error);
        }
    };

    const redirectPage = (): boolean => {
        let destructUrlSlash: string[];
        destructUrlSlash = document.location.pathname.split('/');
        const urlParams = new URLSearchParams(location.search);
        if (
            destructUrlSlash.length > 0 &&
            destructUrlSlash.filter((element) => !isNaN(parseInt(element)) || element == 'driving_assistance').length >
                0
        ) {
            return true;
        } else if (Object.keys(Object.fromEntries(urlParams)).length > 0) {
            return true;
        }

        return false;
    };

    const { isLoading: customersDataLoading, data: customerData } = useQuery(
        ['CustomerCodebook', userInfo.user?.id],
        () => customerClient.getAvailableAsCodebook(),
        {
            refetchOnWindowFocus: false,
            onSuccess: (Customers) => {
                setCustomers(Customers);
                setRegionsData(parseDataForSelector(Customers));
            }
        }
    );

    const searchData = () => {
        const updatedRegions =
            customerData &&
            !!customerData.length &&
            customerData?.filter((customer) =>
                customer?.name?.toLowerCase().includes(searchCustomer ? searchCustomer?.toLowerCase() : '')
            );

        updatedRegions && setRegionsData(parseDataForSelector(updatedRegions, searchCustomer ? true : false));
    };

    const closeRegions = (): void => {
        const updatedRegions = regionsData.map((region) => {
            region.open = false;
            return region;
        });
        setRegionsData(updatedRegions);
    };

    const { mutateAsync } = PatchAccountMutation({
        onSuccess: () => {}
    });
    const handleOnClosePopover = () => {
        setSelectorState({ ...selectorState, isOpen: false });
    };

    const onInputChange = (e) => {
        setSearchCustomer(e.target.value);
    };

    const openRegion = (regionId: number | null): void => {
        const updatedRegions = regionsData.map((region) => {
            if (region.id === regionId) {
                region.open = !region.open;
            }
            return region;
        });
        setRegionsData(updatedRegions);
        ``;
    };

    const handleOnChangeCustomer = async (customerId = 12) => {
        try {
            if (source) {
                source.cancel('Changing Customer');
            }

            setNewCancelToken();

            setFullScreenLoaderState({ open: true });
            resetCustomerSettings();
            resetSearchVehicle();
            resetSelectedVehicle();

            await mutateAsync({ data: { customer: customerId }, extendUrl: 'change-customer' });

            !!redirectPage() && navigate('/dashboard');
        } catch (error) {
            false;
        } finally {
            setNewCancelToken();
            setSelectorState({ isOpen: false });
            setSearchCustomer(undefined);
            closeRegions();
            getUser();
        }
    };

    const parseDataForSelector = (customers: CustomerCodebook[], collapseOpen = false): AutoCompleteOption[] => {
        let dataResponse: AutoCompleteOption[] = [];
        let data = customers;

        data &&
            !!data.length &&
            (data ?? []).map((item) => {
                if (dataResponse.length) {
                    let region = dataResponse.find((data) => data.id === item.region?.id);

                    if (region) {
                        region.customers.push({
                            title: item.name || '',
                            selected: UserState?.customer.id === item.id,
                            hostname: item.hostname || '',
                            id: item.id
                        });
                    } else {
                        dataResponse.push({
                            id: item.region?.id,
                            translationString: item.region?.translationString || '',
                            open: collapseOpen,
                            selected: UserState?.customer?.region?.id === item.region?.id,
                            customers: [
                                {
                                    id: item.id,
                                    title: item.name || '',
                                    hostname: item.hostname || '',
                                    selected: UserState?.customer.id === item.id
                                }
                            ]
                        });
                    }
                } else {
                    dataResponse.push({
                        id: item.region?.id,
                        translationString: item.region?.translationString || '',
                        open: collapseOpen,
                        selected: UserState?.customer?.region?.id === item.region?.id,
                        customers: [
                            {
                                id: item.id,
                                title: item.name || '',
                                hostname: item.hostname || '',
                                selected: UserState?.customer.id === item.id
                            }
                        ]
                    });
                }
            });
        return dataResponse.sort((a, b) => -b.translationString.localeCompare(a.translationString));
    };

    useEffect(() => {
        searchData();
    }, [searchCustomer]);

    useEffect(() => {
        setTimeout(() => {
            setFullScreenLoaderState({ open: false });
            customerData && setRegionsData(parseDataForSelector(customerData));
        }, 3000);
    }, [userInfo.user?.customer]);

    const generateContent = () => {
        if (customersDataLoading) {
            return (
                <LoadingContent>
                    <CircularProgress size='1em' />
                </LoadingContent>
            );
        } else {
            if (regionsData.length) {
                return regionsData.map((value) => [
                    <>
                        <RegionMenuItem
                            key={`${value.id}-region`}
                            onClick={() => {
                                openRegion(value.id);
                            }}
                            selected={value.selected}
                        >
                            <FontAwesomeIcon icon={['fas', 'globe-europe']} />{' '}
                            {translate(`t.${value.translationString}`)}
                            <span key={`${value.id}-icon`}>
                                {value.open ? (
                                    <FontAwesomeIcon icon={['fas', 'chevron-up']} size='xs' />
                                ) : (
                                    <FontAwesomeIcon icon={['fas', 'chevron-down']} size='xs' />
                                )}
                            </span>
                        </RegionMenuItem>

                        <CollapseTable key={`${value.id}-collapse`} in={value.open} timeout='auto' unmountOnExit>
                            {value.customers.map((data, key) => (
                                <LocationMenuItem
                                    key={`${key}-customer`}
                                    selected={data.selected}
                                    onClick={() => {
                                        handleOnChangeCustomer(data.id);
                                    }}
                                >
                                    {data.title}
                                </LocationMenuItem>
                            ))}
                        </CollapseTable>
                    </>
                ]);
            }

            return <CustomText>{translate('t.there_no_data')}</CustomText>;
        }
    };

    return (
        <CustomerSelectorContainer>
            <CustomerSelectorPopover
                className={'popover-customer-selector'}
                id={'popover-customer-selector'}
                open={selectorState.isOpen}
                onClose={handleOnClosePopover}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                anchorEl={document.getElementById('customer-selector')}
            >
                <>
                    <SearchMenuItem key='input-search' disableRipple={true} disableGutters={true}>
                        <SearchTextField
                            label={translate('t.customer')}
                            value={searchCustomer}
                            onChange={onInputChange}
                            style={{ width: '300px' }}
                            size='small'
                            InputLabelProps={{
                                shrink: true
                            }}
                            variant='outlined'
                            onKeyDown={(e) => {
                                e.stopPropagation();
                            }}
                        />
                    </SearchMenuItem>
                    <UiScroll maxHeight='78vh'>{generateContent()}</UiScroll>
                </>
            </CustomerSelectorPopover>
        </CustomerSelectorContainer>
    );
};

export default Wrapper(CustomerSelector);
