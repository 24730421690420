import React, { useMemo } from 'react';
import {
    BESLabel,
    BESText,
    HubIconContainer,
    IconWrapper,
    ListWrapper,
    Name,
    NoVehicleIcon,
    VehicleList,
    VehicleListItem,
    VehicleStatusListViewContent,
    VehicleUpdateTime
} from './VehicleStatusList.style';
import { VehicleBoxProps, VehicleStatusGridViewProps } from './VehicleStatusList.type';
import { applyStyleByMode, getBesRank, getLastValidatedTempPredOutOfPeriod } from 'helpers';
import { useRecoilValue } from 'recoil';
import { CustomerSettings } from 'states/global/CustomerSettings';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getImgSource } from 'helpers/image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRouter } from '@fortawesome/pro-regular-svg-icons';
import VehicleStatusIcons from 'components/Vehicle/VehicleStatusIcons/VehicleStatusIcons';
import { Theme } from 'states/global/Theme';
import useConverter from 'components/CustomHooks/Converter/Converter';
import { WHITE } from 'components/Ui/colors';
import UiIcon from 'components/Ui/Components/UiIcon/UiIcon';
import { DateTime } from 'luxon';

const VehicleBox: React.FC<VehicleBoxProps> = (props): JSX.Element => {
    const ThemeMode = useRecoilValue(Theme);
    const { fromUTCToTimezone } = useConverter();
    const customerSettings = useRecoilValue(CustomerSettings);
    const { t: translate } = useTranslation();
    const navigate = useNavigate();
    const isSelected = useMemo(
        () => props.vehicleId === props.data.vehicle.id,
        [props.vehicleId, props.data.vehicle.id]
    );

    return (
        <VehicleListItem
            alertlevel={props.getHighestAlert(props.data.alert)}
            selected={isSelected}
            $disableVehicleSelection={props.isLoadingVehicle}
            onClick={() => {
                if (!isSelected) {
                    const url: string = location.href;
                    !props.isLoadingVehicle && props.setVehicleId(props.data.vehicle.id);

                    url.includes('vehicle-status')
                        ? navigate(`?id=${props.data.vehicle.id}`, { replace: true })
                        : navigate(`vehicle-status?id=${props.data.vehicle.id}`, { replace: true });
                }
            }}
            title={props.data.device?.id ? `${props.data.device.id}` : `${translate('t.no_hub_attached')}`}
            data-testid='VehicleStatus-SelectVehicle-List-Button'
        >
            <IconWrapper
                $outOfService={props.data.vehicle.outOfService}
                data-testid='VehicleBoxList-VehicleIconWrapper-id'
            >
                {props.data.vehicle.icon ? (
                    <>
                        {props.data.vehicle.vehicleConfigurationId === 9 ? (
                            <HubIconContainer data-testid='HubIcon'>
                                <FontAwesomeIcon
                                    size='lg'
                                    icon={faRouter}
                                    color={applyStyleByMode({
                                        theme: ThemeMode?.mode,
                                        light: '#0000008a',
                                        dark: WHITE
                                    })}
                                />
                            </HubIconContainer>
                        ) : (
                            <img width={'35px'} alt='vehicle' src={getImgSource(`${props.data.vehicle.icon}`)} />
                        )}
                    </>
                ) : (
                    <NoVehicleIcon data-testid='VehicleBoxList-novehicle-UiIcon-id'>
                        <UiIcon
                            icon={['fas', 'ban']}
                            size='sm'
                            transform='down-1'
                            color={applyStyleByMode({
                                theme: ThemeMode?.mode,
                                dark: WHITE
                            })}
                        />
                    </NoVehicleIcon>
                )}
            </IconWrapper>
            <Name
                title={props.data.vehicle.name}
                alertLevel={props.getHighestAlert(props.data.alert)}
                data-testid='VehicleName'
            >
                {props.data.vehicle.name}
            </Name>

            <VehicleStatusIcons
                data={props.data}
                displayAlerts={props.displayAlerts}
                lastTemperaturePredictedAt={props.data.vehicle.lastTemperaturePredictedAt}
            />

            <VehicleUpdateTime
                selected={props.data.vehicle.id === props.vehicleId}
                data-testid={`VehicleStatusList-DateTime-${props.data.vehicle.id}`}
            >
                {props.data.vehicle.vehicleInfo.measuredAt !== null
                    ? fromUTCToTimezone(props.data.vehicle.vehicleInfo.measuredAt, false)
                    : '-'}
            </VehicleUpdateTime>

            {customerSettings.mdd_bes && props.data.highestBes > 0 && (
                <BESLabel
                    rank={getBesRank(customerSettings.mdd_bes_display_name.toUpperCase(), props.data.highestBes)}
                    data-testid='VehicleBoxList-BES-UiIcon-id'
                >
                    <BESText>
                        {customerSettings.mdd_bes_display_name.toUpperCase()}:{props.data.highestBes}
                    </BESText>
                </BESLabel>
            )}
        </VehicleListItem>
    );
};

const createList = (props: VehicleStatusGridViewProps): JSX.Element => {
    const vehicleList: JSX.Element[] =
        props.data?.vehicleStatus.map((vehicle, index) => (
            <VehicleBox
                data={vehicle}
                key={index}
                isLoadingVehicle={props.isLoadingVehicle}
                setVehicleId={props.setVehicleId}
                vehicleId={props.vehicleId}
                displayAlerts={props.displayAlerts}
                getHighestAlert={props.getHighestAlert}
            />
        )) || [];

    return (
        <ListWrapper>
            <VehicleList>{vehicleList}</VehicleList>
        </ListWrapper>
    );
};

export const VehicleStatusListContent: React.FC<VehicleStatusGridViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();

    return (
        <VehicleStatusListViewContent data-testid='VehicleStatusListContent'>
            {props.data?.vehicleStatus.length ? (
                createList(props)
            ) : (
                <Typography align='center' marginTop={1} variant='subtitle1'>
                    {translate('t.there_no_data')}
                </Typography>
            )}
        </VehicleStatusListViewContent>
    );
};
