import PortalApi from 'helpers/api/PortalApiClient';
import { getParams } from 'helpers';
import { LoggerAction, LoggerRequestModel } from '../models/LoggerRequest.type';
import { Paginator } from 'helpers/api/type';

export default class LoggerRequest extends PortalApi<LoggerRequestModel> {
    route = 'logger-request';

    getUserActionsTable = async (props): Promise<Paginator<LoggerRequestModel>> => {
        return await this.getTable({ criteria: getParams(props) });
    };

    getAction = async (): Promise<LoggerAction> => {
        return await this.getCodebook({ extendUrl: 'action' });
    };
}
